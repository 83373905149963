<template>
    <w-icon
        v-if="item.type === mediaTypes.FOLDER"
        value="FOLDER"
        color="warning"
        :size="sizeIcon"
        v-bind="iconBindings"
    />
    <v-img
        v-else-if="item && isImage"
        :src="item.url"
        :max-width="sizeIcon"
        :max-height="sizeIcon"
        v-bind="iconBindings"
    />
    <img
        v-else-if="item && iconPath"
        :width="sizeIcon + 'px'"
        :height="sizeIcon + 'px'"
        :src="iconPath"
        v-bind="iconBindings"
    />
    <w-icon
        v-else-if="(item && item.type === mediaTypes.LINK) || !iconPath"
        value="OPEN_IN_NEW"
        color="primary"
        :size="sizeIcon"
        v-bind="iconBindings"
    />
</template>

<script>
import { isImage, iconPath } from '../tools'
import { mediaTypes } from '@apps/media/tools/config'

export default {
    name: 'MediaIcon',
    props: {
        item: {
            type: Object,
            default: null
        },
        size: {
            type: [ Number, String ],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        sizeIcon() {
            if(this.size) {
                return this.size
            }

            if(this.item.type === mediaTypes.FOLDER) {
                return 28
            }

            if(isImage(this.item.src)) {
                return 28
            }

            return 20
        },
        mediaTypes() {
            return mediaTypes
        },
        isImage() {
            return isImage(this.item.src)
        },
        iconPath() {
            return iconPath(this.item.src)
        },
        iconBindings() {
            const bindings = {}

            if(this.disabled) {
                bindings.style = {
                    opacity: '0.4'
                }
            }

            return bindings
        }
    }
}
</script>

<style scoped>

</style>
