<template>
    <div class="upload-file-form">
        <upload-file-form-drop-area
            v-if="selectedFiles.length === 0"
            @input="uploadFilesHandler"
        />
        <upload-file-form-progress
            v-else
            :items="selectedFiles"
        />
    </div>
</template>

<script>
import { uploadFile } from '@apps/media/api'
import UploadFileFormDropArea from '@apps/media/components/MediaUploadForm/UploadFileForm/UploadFileFormDropArea'
import UploadFileFormProgress from '@apps/media/components/MediaUploadForm/UploadFileForm/UploadFileFormProgress'
import { uploadStatus } from '@apps/media/tools/config'
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'
import Debugger from '@/utils/Debugger'

export default {
    name: 'UploadFileForm',
    mixins: [uploadFormMixin],
    components: { UploadFileFormProgress, UploadFileFormDropArea },
    data() {
        return {
            selectedFiles: []
        }
    },
    methods: {
        async uploadFilesHandler(files) {
            this.$emit('loading', files.length)

            files.forEach((item, index) => {
                this.selectedFiles.push({
                    file: item,
                    status: uploadStatus.LOADING
                })
            })

            for (let i = 0; i < files.length; i++) {
                try {
                    const response = await uploadFile({ file: files[i], parent: this.parent })

                    this.selectedFiles[i].status = uploadStatus.SUCCESS

                    this.$emit('sequence', response.data.data)
                } catch (error) {
                    Debugger.log(error)
                    this.selectedFiles[i].status = uploadStatus.ERROR
                }
            }

            this.$emit('success', { message: this.$trans('Uploaded successful') })
        }
    }
}
</script>

<style lang=scss>
</style>
