<template>
    <div
        ref="dropArea"
        id="drop-area"
        class="border-dashed"
    >
        <form class="my-form">
            <p>{{ $trans('hint.media.uploadFilesDropZone') }}</p>
            <input
                ref="fileInput"
                type="file"
                id="fileElem"
                multiple
                :accept="acceptTypes"
                @change="uploadFilesHandler"
            >
            <v-btn
                outlined
                small
                color="primary"
                @click="onFocusFileInput"
            >
                <w-icon small left value="UPLOAD"/>
                {{ $trans('Select files') }}
            </v-btn>
        </form>
    </div>
</template>

<script>
import { extension } from '@apps/media/tools'
import Notification from '@apps/notification/utils'

export default {
    name: 'UploadFileFormDropArea',
    computed: {
        acceptTypes() {
            const acceptTypes = []

            this.$store.getters['media/enabledFileTypes'].forEach(type => {
                acceptTypes.push(`.${ type }`)
            })

            return acceptTypes.join(',')
        }
    },
    data() {
        return {
            dropArea: null
        }
    },
    mounted() {
        // Handle dropped files
        this.dropArea = this.$refs.dropArea
        this.dropArea.addEventListener('drop', this.handleDrop, false)

        // Prevent default drag behaviors
        ;[ 'dragenter', 'dragover', 'dragleave', 'drop' ].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.preventDefaults, false)
            document.body.addEventListener(eventName, this.preventDefaults, false)
        })

        // Highlight drop area when item is dragged over it
        ;[ 'dragenter', 'dragover' ].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.highlight, false)
        })

        ;[ 'dragleave', 'drop' ].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.unHighlight, false)
        })
    },
    methods: {
        preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        highlight(e) {
            this.dropArea.classList.add('highlight')
        },
        unHighlight(e) {
            this.dropArea.classList.remove('highlight')
        },
        handleDrop(e) {
            const dt = e.dataTransfer
            const files = dt.files

            this.uploadFilesHandler(files)
        },
        uploadFilesHandler(e) {
            const files = e.target
                ? [ ...e.target.files ]
                : [ ...e ]

            const enabledFileTypes = this.$store.getters['media/enabledFileTypes']

            let areFilesValid = true

            for (let i = 0; i < files.length; i++) {
                if(!enabledFileTypes.includes(extension(files[i].name))) {
                    areFilesValid = false
                    break
                }
            }

            if(areFilesValid) {
                this.$emit('input', files)
            } else {
                const notify = new Notification(this.$trans('validation.mimes', {
                    values: enabledFileTypes.join(','),
                    attribute: this.$trans('Files')
                }), {
                    type: Notification.TYPE.ERROR
                })

                notify.open()
            }
        },
        onFocusFileInput() {
            this.$refs.fileInput.click()
        }
    }
}
</script>

<style lang=scss>
#drop-area {
    border-width: 2px;
    border-color: #ccc;
    border-radius: 4px;
    width: 100%;
    min-height: 216px;
    font-family: sans-serif;
    padding: 60px 26px;
    text-align: center;

    &.highlight {
        border-color: var(--v-primary-base);
    }

    p {
        margin-top: 0;
        margin-bottom: 30px;
    }

    #fileElem {
        display: none;
    }
}
</style>
